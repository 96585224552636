import React from 'react'

const JournalPitch = props => {

    const handlechange = e => {
        props.setPitch_journal(e.target.value)
        
    }
      
    const Pitch = ( () => {
        
        
        
        switch (props.hauteur_journal) {
            
            case 'h160' :
                return (
                    <div className="Line">
                         
                        <input type="radio" id="P15" value="P15" onClick={(e) =>handlechange(e)} />
                        <label htmlFor='P15' className='listCroixrRadio'>Pitch : 15 mm</label>
                    </div>
                )
            case 'h250' : 
                
                return (
                    <div className='Bloc'>
                         
                        <div className="Line">  
                            <input type="radio" id="P13,5" name="pitchjournal" value="P13,5"  onClick={(e) =>handlechange(e)} />
                            <label htmlFor='P13,5' className='listCroixrRadio'>Pitch : 13,5 mm</label>
                            
                        </div>

                        <div className="Line">  
                            <input type="radio" id="P271" name="pitchjournal" value="P27" onClick={(e) =>handlechange(e)}/>
                            <label htmlFor="P271" className='listCroixrRadio'>Pitch : 27 mm</label>
                        </div>
                    </div>
                ) 
            case 'h480' :
                return (
                    <div className="Line"> 
                         
                      <input type="radio" id="P27" value="P27" onClick={(e) =>handlechange(e)} />
                      <label htmlFor='P27' className='listCroixrRadio'>Pitch : 27 mm</label>
                    </div>
                    )    
            default :
                    return null
        }
    })
    
    return (
        <div>
            <div className='croixlineone'>
                <fieldset className='journalField'>
                <legend className="croixlegendd">Pitch :</legend>
                    
                    {Pitch()}

                </fieldset>    
            </div>
        </div>
    )
}

export default JournalPitch
