import { Link } from 'react-router-dom'
import logo from './IMG/logo_new_ways.png'

const Logo = () => {
    return (
        <div>
            <div id="logoFirstLine">
                <div id="logo">
                    <img id="logo-img" src={logo} alt="Logo New Ways"/>
                </div>
                <div>
                    <h1 id="logoFirstLinep"><span>Une nouvelle </span><span>Communication </span><span>pour votre pharmacie</span></h1>
                </div>
                <div>
                    <Link to="/" className='Accueiltextlogo'>Accueil </Link>
                </div>
            </div>
        </div>
    )
}

export default Logo