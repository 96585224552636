import React from 'react'


const FooterCopyRight = () => {
    return (
        <div id="footercopyrightdiv">
            <p id="p1">Copyright © 2021 - Tous droit réservés</p>
            <p id="p2">Designed & developed by Reza</p>
            <p id="p1r">© 2021</p>
            <p id="p2r">Designed & developed by Reza</p>
        </div>
    )
}

export default FooterCopyRight
