import React from 'react'

function ErrorPage() {
    return (
        <div>
           <h1>Page not found</h1> 
        </div>
    )
}

export default ErrorPage
