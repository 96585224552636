import React, { useState, useEffect } from 'react'
import './journal.css'
import DataJournal from './journalData.json'
import JournalHauteur from './Journal_hauteur'
import JournalLongeur from './Journal_Longeur'
import JournalPitch from './JournalPitch'
import Journalface from './Journalface'
import JournalCouleur from './JournalCouleur'
import Imgjournal from './Imgjournal'
import Slider from './Slider'
import Demandedevis from '../../main/Demandedevis'


const Journal = () => {

    const [hauteur_journal, setHauteur_journal] = useState('')
    const [pitch_journal, setPitch_journal] = useState('')
    const [longeur_journal, setLongeur_journal] = useState('')
    const [journal_face, setJournal_face] = useState('')
    const [couleur_journal, setCouleur_journal] = useState('')
    const [img_small, setImg_small] = useState('')
    const [img_big, setImg_big] = useState('')
    const [text, setText] = useState('')
    const [code, setCode] = useState('')

    useEffect(() => {
       {/*} setPitch_journal("")
         setLongeur_journal("")*/}
        list()
        
    },[hauteur_journal,journal_face,couleur_journal,longeur_journal,pitch_journal]) 

    const list = () => {
        setText("")
        DataJournal.map(post => {
        
        if ( (post.faces === journal_face) && (post.hauteur === hauteur_journal) && (post.pitch === pitch_journal) && (post.longeur === longeur_journal) && (post.couleur === couleur_journal)){
            setText(post.text)
            setImg_small(post.img_small) 
            setImg_big(post.img_big)
            setCode(post.code)
        } 
    })
}
    return (
        <div>
            <h1 className='h1texte'>NOS JOURNAUX LUMINEUX SE DECLINENT EN 4 HAUTEURS DE TEXTE ET 8 LONGUEURS. ILS PERMETTENT UNE VISIBILITE 
PARFAITE DE VOS HORAIRES ET DE VOS MESSAGES.
• HEURES-DATES-TEMPERATURES • MODE ECO • PROGRAMMATION DE L’HEURE D’AFFICHAGE • POSSIBILITE DE TEXTE VERTICAL.
</h1>
            <Slider/>
            <div id="croixContainer">
                <p>Renseignez les caractéristiques de Journaux lumineux :</p>

                <div id="croixlineone">
                    <Journalface setJournal_face={setJournal_face} list={list}/>
                    <JournalHauteur setHauteur_journal={setHauteur_journal} list={list}/>
                    <JournalPitch hauteur_journal={hauteur_journal} setPitch_journal={setPitch_journal} list={list}/>
                    <JournalCouleur setCouleur_journal={setCouleur_journal} list={list}/>
                    

                </div>
                <div id="croixlinetwo">
                  <JournalLongeur hauteur_journal={hauteur_journal} setLongeur_journal={setLongeur_journal} list={list}/>
                  <Demandedevis code={code}/>
                  <Imgjournal img_small={img_small} img_big={img_big}/>
                </div>
                <div> 
                <fieldset className="croixField2" >  
                    <legend  className="croixlegendd">Informations techniques :</legend>
                    <p className='croixtext'>{text}</p> 
                </fieldset>     
            </div> 
                {/*"faces : "+journal_face+ " Hauteur : "+hauteur_journal+" Pitch : "+pitch_journal+" Couleur : "+couleur_journal+ " Longeur : "+longeur_journal*/}
            </div>
        </div>
    )
}

export default Journal