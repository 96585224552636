import React  from 'react'

const Journal_hauteur = props => {

    
    return (
        <div className='croixlineone'>
            <fieldset className='journalField'>
                <legend className="croixlegendd"> Hauteur :</legend>

                <div className='Bloc'>
                    <div className="Line">  
                        <input type="radio" id="h160p15" name="hauteurjournal" value="h160" onClick={(e) =>props.setHauteur_journal(e.target.value)}/>
                        <label htmlFor='h160p15' className='listCroixrRadio'>Hauteur: 160 mm</label>
                    </div>

                    <div className='Line'>
                        <input type="radio" id="h250p13" name="hauteurjournal" value="h250" onClick={(e) =>props.setHauteur_journal(e.target.value)}/>
                        <label htmlFor='h250p13' className='listCroixrRadio'>Hauteur: 250 mm</label>
                    </div> 
                   
                    <div className='Line'> 
                        <input type="radio" id="h480p27" name="hauteurjournal" value="h480" onClick={(e) =>props.setHauteur_journal(e.target.value)}/>
                        <label htmlFor='h480p27' className='listCroixrRadio'>Hauteur: 480 mm</label>
                    </div>
                </div>
            </fieldset>
            
        </div>
    )
}

export default Journal_hauteur
