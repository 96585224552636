import React from 'react'
import './quisommenous.css'
import atelier1 from './IMG/atelier1.jpg'
import atelier2 from './IMG/atelier2.jpg'
import atelier3 from './IMG/atelier3.jpg'

const Quisommenous = () => {
    return (
        <>
            <div className="quisommenous_divtext">
                <h1 className='quisommenous_h1' >FABRICANT FRANÇAIS  DE PRODUITS DESTINÉS AU MONDE DE L’ENSEIGNE<br/>
                    NOUS VOUS PROPOSONS DES PRODUITS PERFORMANTS AU MEILLEUR RAPPORT QUALITE-PRIX.<br/>
                    NOUS NOUS CHARGEONS DE LA COMMUNICATION ET DES POINTS DE VENTE DE VOTRE RESEAU:<br/> ENSEIGNE, 
                    SIGNALÉTIQUE, AFFICHAGE, PLV.
                    NOUS DISPOSONS AUJOURD’HUI DE PLUS DE 6000 M2 
                    D’ATELIER<br/> CE QUI NOUS PERMET D’ACCROITRE NOTRE 
                    PRODUCTIVITE ET SURTOUT D’AMELIORER LA QUALITE DU SERVICE QUE NOUS SOUHAITONS VOUS APPORTER.<br/>
                    AVEC NEW WAYS CONCRETISEZ VOS PROJETS EN BENEFICIANT DE TOUTES NOS INNOVATIONS. 
                </h1>
            </div>
            <div className="quisommenous_images">
                <img src={atelier1} alt="Atelier" id="atelier1"/>
                <img src={atelier2} alt="Atelier" id="atelier2"/>
                <img src={atelier3} alt="Atelier" id="atelier3"/>
            </div>
            <h2 className='quisommesnous_h2'>
                    NEW WAYS - 15, RUE HENRI ROL TANGUY - 91180 SAINT-GERMAIN-LES-ARPAJON<br/>
                    TEL: 06 77 03 24 51 - MAIL : newwayscommunications@gmail.com 
            </h2>
            
        </>
    )
}

export default Quisommenous
