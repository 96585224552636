import React from 'react'

const Journalface = props => {
    return (
        <div>
            <div className='croixlineone'>
            <fieldset className='journalField'>
                <legend className="croixlegendd">Faces :</legend>

                <div className='Bloc'>
                    <div className="Line">  
                        <input type="radio" id="simpleface" name="facejournal" value="SF" onClick={(e) =>{props.setJournal_face("SF") ; props.list()}} />
                        <label htmlFor='simpleface' className='listCroixrRadio'>Simple Face</label>
                    </div>

                    <div className='Line'>
                        <input type="radio" id="doubleface" name="facejournal" value="DF" onClick={(e) =>{props.setJournal_face("DF") ; props.list()}}/>
                        <label htmlFor='doubleface' className='listCroixrRadio'>Double Faces</label>
                    </div> 
                   
                </div>
            </fieldset>
            
        </div>
        </div>
    )
}

export default Journalface
