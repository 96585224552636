import React from 'react';
import './cgv.css'

const Cgv = () => {
    return (
        <>
    <h1>Conditions Générales de Vente</h1>

    <p><strong>Dispositions générales</strong></p>
    <p>En l’absence d’un accord spécial et écrit de notre part, toute commande ou acceptation de devis entraîne sans réserve l’adhésion pour l’acheteur aux présentes conditions générales de vente.</p>

    <p><strong>Descriptif des produits</strong></p>
    <p>Nous nous réservons le droit d’apporter, sans préavis, toutes modifications techniques, esthétiques ou de tout ordre à nos produits sans avoir à modifier pareillement les produits commandés, livrés, ou à livrer.</p>
    <p>Les caractéristiques techniques, dimensions, performances et autres données figurant dans les catalogues, prospectus, circulaires, ou tous autres documents ont le caractère de simple indication.</p>
    <p>Dans le cas où un fichier est envoyé par le client à ESP pour fabriquer un produit, ce fichier tient lieu de descriptif du produit commandé. Notre responsabilité est entièrement dégagée sur les défauts quels qu’ils soient dans l’hypothèse où ces défauts sont présents sur le(s) fichier(s) du client.</p>

    <p><strong>Prise de commande</strong></p>    
    <p>Toute commande non confirmée par un bon pour accord écrit ne pourra entraîner un litige avec notre société. Toute modification ou résolution de la commande demandée par le client ne peut être prise en considération qu’avec l’accord exprès  de notre société.</p>

    <p><strong>Annulation de commande</strong></p>
    <p>Toute annulation de commande ne peut être prise en compte sans l’accord exprès  d’ESP.
        Sans cet accord, le client devra indemniser ESP pour la totalité des frais engagés (dont notamment, frais d’étude, outillages, pièces, matières, services...).</p>    
  
    <p><strong>Prix et conditions de paiement</strong></p>    
    <p>Les ventes et prestations sont fournies au tarif en vigueur au jour de la réception de la commande. Les tarifs sont modifiables sans préavis. Les prix s’entendent nets, hors taxe, départ d’usine et en euros. Les frais de port et d’emballage sont à la charge de l’acheteur.</p>
    <p>Pour tout client ne possédant pas de compte ouvert dans nos livres, les ventes et prestations
        sont payables au comptant, par chèque, à la commande.</p>
    <p>En tout état de cause, un formulaire d’ouverture de compte ne pourra  être accepté qu’après
        le paiement d’au moins une commande au comptant. Le compte sera ouvert après enquête bancaire et vérification du code APE. Sauf conditions particulières précisées par écrit,
        les paiements se font soit comptant par chèque à la commande, par CB, par virement soit par effet de commerce (LCR automatique).</p>
    <p>En cas de retard de paiement, application d’une pénalité exigible le jour suivant la date
        de règlement figurant sur la facture et qui sera égale au taux directeur semestriel de la banque centrale européenne majoré de 10 points  (Loi de modernisation  de l’économie du 4 août 2008)
        et d’une indemnité forfaitaire de 40,00 € pour frais de recouvrement, dont le montant est fixé par décret (art L.442-6, al.12 et L. 441-3 du Code de Commerce).</p>
    <p>Une indemnisation complémentaire pourra être exigée lorsque les frais de recouvrement sont supérieurs à ce montant.</p>
    <p>Ces stipulations ne font pas obstacle à la faculté pour notre société de procéder par toutes
        voies de droit au recouvrement des factures échues, de suspendre les expéditions, ou d’annuler les commandes</p>
    <p>Les pénalités de retard et l’indemnité forfaitaire pour frais de recouvrement sont exigibles sans qu’une mise en demeure soit nécessaire au préalable. Le non-respect d’une seule échéance
        ou le défaut d’acceptation d’une traite rend immédiatement exigible le paiement de toute autre somme due non échue, sans qu’aucun escompte ne puisse être exigé.</p>
    <p>À titre de clause pénale, l’acheteur sera redevable pour le retard de paiement d’un montant forfaitaire de 15% des sommes restant dues avec un minimum de 200,00 euros H.T.</p>

    <p><strong>Transport</strong></p>    
    <p>Les marchandises voyagent aux risques et périls du destinataire,  quelles que soient
        les conditions d’expédition arrêtées avec la commande. En aucun cas, un retard de livraison
        ne pourra donner lieu à une indemnité.</p>    

    <p><strong>Livraisons et réception des marchandises</strong></p>
    <p>Le réceptionnaire doit vérifier la quantité, le poids, et la qualité des marchandises livrées
        et réserver ses droits, si nécessaire, auprès du transporteur, immédiatement au moment de
        la livraison. Les réserves doivent être aussi précises, détaillées et complètes que possible.</p>    
    <p>Des mentions générales du type «sous réserve», «colis abîmé»  ou «marchandise abîmée» 
        n’ont aucune valeur juridique. Nous déclinons toute responsabilité si cette procédure n’est pas respectée.</p>
    <p>Les réclamations sur les vices apparents ou la non-conformité des fournitures doivent être formulées par écrit et adressées à la société dans les 5 jours suivant leur livraison. La notification d’une réclamation ne dispense pas le client de régler sa facture à l’échéance prévue.</p>    
    
    <p><strong>Délai</strong></p> 
    <p>Les délais sont donnés à titre indicatif. Un retard de livraison ne peut entraîner ni le refus
        de la fourniture, ni la résiliation de la commande, ni le paiement de quelconques indemnités.</p>   

    <p><strong>Garantie</strong></p>
    <p>Nos produits sont garantis un an  pièces et main d’œuvre contre tous vices de fabrication.
        Cette garantie exclut le cas de comportement fautif imputable à l’utilisateur et s’entend pour
        des conditions d’utilisation du matériel en conditions normales</p>
    <p>Lorsque la garantie est applicable, elle ne pourra jamais aller au-delà du remplacement de
        la marchandise reconnue défectueuse par notre service qualité.</p>    
    <p>La garantie contractuelle n’ouvre pas droit pour le bénéficiaire de la garantie à quelques dommages et intérêts que ce soit.</p> 
    <p>Cette garantie ne peut avoir en aucun cas pour effet de prolonger le délai de paiement.</p>   
    <p>Les frais de port pour le retour du matériel concerné et l’envoi du matériel en échange sont
        à la charge du client.</p>

    <p><strong>Responsabilité</strong></p> 
    <p>Toutes nos fabrications sont fournies sans note de calcul. En cas de non-conformité ou de pose sans autorisation, nous ne pourrons être tenus pour responsables. La responsabilité
        du fournisseur est strictement limitée aux obligations définies dans le contrat.</p>   
    <p>Cette responsabilité est exclue, sous réserve des dispositions légales impératives,
        en cas de dommage indirect et immatériel, tel que la perte de revenu, la perte de gain, la perte d’exploitation, le coût financier, la perte de commande, un trouble commercial quelconque,
        et tout autre préjudice commercial ou financier etc., le client renonçant tant en son nom
        qu’au nom de ses assureurs à tout recours contre le fournisseur et ses assureurs.</p>
    <p>En tout état de cause, la totalité des conséquences financières résultant de la responsabilité
        de la société, au titre de nos diverses obligations, ne pourra excéder le montant du contrat</p> 

    <p><strong>Transfert de propriété</strong></p>  
    <p>La propriété de la fourniture vendue ne sera transférée au client qu’après complet encaissement par le fournisseur. Cependant la charge des risques de détérioration, de perte ou de vol
        de la fourniture sera transférée au client dès la réception.</p>  
    <p>En cas de redressement judiciaire ou de liquidation judiciaire du client,  le fournisseur aura le droit de revendiquer la fourniture vendue, conformément aux dispositions de la législation française.</p>

    <p><strong>Juridiction</strong></p>
    <p className='lastp'>En cas de contestation de tout ou partie des présentes conditions de vente, comme pour toutes celles pouvant naître de l’exécution d’une quelconque commande, le tribunal de commerce d’Evry sera seul compétent.</p>
    
            
        </>
    );
};

export default Cgv;