import React from 'react'
import { Link } from 'react-router-dom'



const Footer = () => {
    return (
        <div id="footerdiv">
            <div className="footercontenu">
                <Link to ="/Quisommenous">Qui sommes nous</Link>
            </div>
            <div className="footercontenu">
                <Link to ="/Cgv" >Conditions Générales de Vente </Link>
            </div>
            <div className="footercontenu">
                <Link to = "/Contact">Contact</Link>
            </div>
            
        </div>
    )
}

export default Footer
