import React from 'react'
import './App.css'; 
import {Route, Routes, HashRouter} from 'react-router-dom'
import Logo from './main/Logo'
import Menu from './main/Menu'
import Accueil from './main/Accueil'
import Errorpage from './main/ErrorPage'
import Croix from './componenets/croix/Croix'
import Enseignes from './componenets/enseignes/Enseignes'
import Journal from './componenets/journal/Journal'
import Lettre from './componenets/lettre/Lettre'
import Footer from './main/Footer'
import FooterCopyRight from './main/FooterCopyRight'
import Quisommenous from './main/Quisommenous'
import Cgv from './main/Cgv'
import Contact from './main/Contact'


function App() {
  
  return (
    < >
      <HashRouter>
        <Logo/>
        <Menu/>
          <Routes>
            <Route exact path="/" element={<Accueil/>}/>
            <Route strict path="/Croix" element={<Croix/>}/>
            <Route strict path="/Enseignes" element={<Enseignes/>}/>
            <Route strict path="/Journal" element={<Journal/>}/>
            <Route strict path="/Lettre" element={<Lettre/>}/>
            <Route strict path="/Quisommenous" element={<Quisommenous/>}/>
            <Route strict path="/Cgv" element={<Cgv/>}/>
            <Route strict path="/Contact" element={<Contact/>}/>
          <Route component={Errorpage}/>
        </Routes>
        <Footer/>
        <FooterCopyRight/>
      </HashRouter>
    </>
  );
}

export default App;
