import React from "react"
 


const Journal_Longeur = props => {
    
    const Longeur = ( () => {
        
        switch (props.hauteur_journal) {
            
            case 'h160' :
                return (
                    <div className='Bloc'>
                         
                    <div className="Line">  
                        <input type="radio" id="750" name="160" value="750"  onClick={(e) =>props.setLongeur_journal(e.target.value)}/>
                        <label htmlFor='750' className='listCroixrRadio'>750 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="1000" name="160" value="1000"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='1000' className='listCroixrRadio'>1000 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="1300" name="160" value="1300"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='1300' className='listCroixrRadio'>1300 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="1600" name="160" value="1600"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='1600' className='listCroixrRadio'>1600 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="1900" name="160" value="1900"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='1900' className='listCroixrRadio'>1900 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="2250" name="160" value="2250"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='2250' className='listCroixrRadio'>2250 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="2550" name="160" value="2550"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='2550' className='listCroixrRadio'>2550 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="2850" name="160" value="2850"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='2850' className='listCroixrRadio'>2850 mm</label>
                    </div>

                    
                </div>
                )
            case 'h250' :
                 
                return (
                    <div className='Bloc'>
                         
                        <div className="Line">  
                            <input type="radio" id="700" name="250" value="700"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                            <label htmlFor='700' className='listCroixrRadio'>700 mm</label>
                        </div>
                        <div className="Line">  
                            <input type="radio" id="1000" name="250" value="1000"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                            <label htmlFor='1000' className='listCroixrRadio'>1000 mm</label>
                        </div><div className="Line">  
                            <input type="radio" id="1300" name="250" value="1300"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                            <label htmlFor='1300' className='listCroixrRadio'>1300 mm</label>
                        </div>
                        <div className="Line">  
                            <input type="radio" id="1600" name="250" value="1600"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                            <label htmlFor='1600' className='listCroixrRadio'>1600 mm</label>
                        </div>
                        <div className="Line">  
                            <input type="radio" id="1900" name="250" value="1900"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                            <label htmlFor='1900' className='listCroixrRadio'>1900 mm</label>
                        </div>
                        <div className="Line">  
                            <input type="radio" id="2200" name="250" value="2200"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                            <label htmlFor='2200' className='listCroixrRadio'>2200 mm</label>
                        </div>
                        <div className="Line">  
                            <input type="radio" id="2500" name="250" value="2500"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                            <label htmlFor='2500' className='listCroixrRadio'>2500 mm</label>
                        </div>
                        <div className="Line">  
                            <input type="radio" id="2800" name="250" value="2800"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                            <label htmlFor='2800' className='listCroixrRadio'>2800 mm</label>
                        </div>

                        
                    </div>
                ) 
            case 'h480' :
                return (
                    <div className='Bloc'>
                         
                    <div className="Line">  
                        <input type="radio" id="1000" name="480" value="1000"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='1000' className='listCroixrRadio'>1000 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="1300" name="480" value="1300"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='1300' className='listCroixrRadio'>1300 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="1600" name="480" value="1600"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='1600' className='listCroixrRadio'>1600 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="1900" name="480" value="1900"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='1900' className='listCroixrRadio'>1900 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="2200" name="480" value="2200"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='2200' className='listCroixrRadio'>2200 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="2500" name="480" value="2500"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='2500' className='listCroixrRadio'>2500 mm</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="2800" name="480" value="2800"  onClick={(e) =>props.setLongeur_journal(e.target.value)} />
                        <label htmlFor='2800' className='listCroixrRadio'>2800 mm</label>
                    </div>

                    
                </div>
                    )    
            default :
                    return null
        }
    })

    return (
        <div>
            <div className='croixlineone'>
                <fieldset className='journalField'>
                <legend className="croixlegendd">Longeur :</legend>

                {Longeur()}

                </fieldset>    
            </div>
            
        </div>
    )
}

export default Journal_Longeur
