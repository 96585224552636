import React from 'react'
import Data from './croixData.json'

const CroixList = props => {

    
    const list = Data.map(post => {
        if ( (post.type_face === props.type_face) && (post.type_color === props.type_color) && (post.type_croix === props.type_croix) && (post.type_programation === props.type_programable))   {
            return(
                <div className="Line" >
                  <input type="radio"  id={post.id} name="list_croix" value={post.name} onClick={(e) =>
                     {props.setNom_croix(e.target.value); props.setCode_croix(post.code); props.setImg_small(post.img_small);
                      props.setImg_big(post.img_big); props.setText(post.text)}}/>
                  <label htmlFor={post.id} className="listCroixrRadio" >{post.name}</label>
                </div>
            )
        }
    })

    return (
        <div>
            <fieldset className="croixField2" >
            <legend  className="croixlegendd">Liste des Croix :</legend>
                <p id="listCroixrRadio">{list}</p>
            </fieldset>
        </div>
    )
}

export default CroixList
