import React, {useState, useEffect} from 'react'
import './Croix.css'
import TypeColor from './TypeColor'
import TypeCroix from './TypeCroix'
import TypeProgrammable from './TypeProgrammable'
import Faces from './Faces'
import CroixList from './CroixList'
import Imgcroix from './Imgcroix'
import Demandedevis from '../../main/Demandedevis'
import Slidercroix from './Slidercroix'

function Croix() {

    useEffect(() => {
        document.title="NewWays Croix"
    },[])

    const [type_croix, setType_croix] = useState('')
    const [type_programable, setType_Programable] = useState('')
    const [type_color, setType_color] = useState('')
    const [type_face, setType_face] = useState('')
    const [nom_croix, setNom_croix] = useState('')
    const [code_croix, setCode_croix] = useState('')
    const [img_small, setImg_small] = useState('')
    const [img_big, setImg_big] = useState('') 
    const [text, setText] = useState('')

    

    return (
        <>
        <h1 className='h1texte'>LES CROIX SONT LE SUPPORT INDISPENSABLE POUR LES PHARMACIES. EN EXTERIEUR OU EN VITRINE, MONOCHROMES 
            OU FULL COLOR, EN PETITES OU  GRANDES TAILLES NOS MODÈLES S’ADAPTENT À TOUTES LES SITUATIONS.
        </h1>
        <Slidercroix/>
        <div id="croixContainer">
           
            <p>Renseignez les caractéristiques de Croix :</p>

            <div id="croixlineone">
                <TypeCroix setType_croix={setType_croix}/>
                <TypeProgrammable setType_Programable={setType_Programable} />
                <TypeColor  setType_color={setType_color}/>
                <Faces setType_face={setType_face}/><br/>
            </div>   

            <div id="croixlinetwo">
                <CroixList setNom_croix={setNom_croix} setCode_croix={setCode_croix} setImg_small={setImg_small} setImg_big={setImg_big} 
                    setText={setText} type_croix={type_croix} type_programable={type_programable} type_color={type_color} type_face={type_face} />
                <Demandedevis code={code_croix}/>
                <Imgcroix code_croix={code_croix} img_small={img_small} img_big={img_big}/>
            </div>
            <div> 
                <fieldset className="croixField2" >  
                    <legend  className="croixlegendd">Informations techniques :</legend>
                    <p className='croixtext'>{text}</p> 
                </fieldset>     
            </div> 
        </div>
        </>
    )
}

export default Croix
