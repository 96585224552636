import React from 'react'

const JournalCouleur = props => {
    return (
        <div>
            <div className='croixlineone'>
            <fieldset className='journalField'>
                <legend className="croixlegendd">Couleur :</legend>

                <div className='Bloc'>
                    <div className="Line">  
                        <input type="radio" id="rouge" name="couleurjournal" value="Rouge" onClick={(e) =>props.setCouleur_journal(e.target.value)}/>
                        <label htmlFor='rouge' className='listCroixrRadio'>Rouge</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="ambre" name="couleurjournal" value="Ambre" onClick={(e) =>props.setCouleur_journal(e.target.value)}/>
                        <label htmlFor='ambre' className='listCroixrRadio'>Ambre</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="jaune" name="couleurjournal" value="Jaune" onClick={(e) =>props.setCouleur_journal(e.target.value)}/>
                        <label htmlFor='jaune' className='listCroixrRadio'>Jaune</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="blue" name="couleurjournal" value="Blue" onClick={(e) =>props.setCouleur_journal(e.target.value)}/>
                        <label htmlFor='blue' className='listCroixrRadio'>Blue</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="vert" name="couleurjournal" value="Vert" onClick={(e) =>props.setCouleur_journal(e.target.value)}/>
                        <label htmlFor='vert' className='listCroixrRadio'>Vert</label>
                    </div>
                    <div className="Line">  
                        <input type="radio" id="blanc" name="couleurjournal" value="Blanc" onClick={(e) =>props.setCouleur_journal(e.target.value)}/>
                        <label htmlFor='blanc' className='listCroixrRadio'>Blanc</label>
                    </div>

                    
                   
                </div>
            </fieldset>
            
        </div>
        </div>
    )
}

export default JournalCouleur
