import React, {useState} from 'react'
import Modal from 'react-modal'
import './lettre.css'
import close from './IMG/close.svg'
import lettre_suspendues_pharma from './IMG/lettres_suspendues_pharma.jpg'
import lettre_pharmacie from './IMG/lettre_pharmacie.png'
import lettre_retro_eclaire from './IMG/lettre_retro_eclaire.jpg'
import lettre_sante from './IMG/lettre_sante.jpg'
import lettre_led_apparebte from './IMG/lettre_led_apparebte.jpg'
import croix_ajour from './IMG/croix_ajour.jpg'
import lettrtes_boitier from './IMG/lettrtes_boitier.jpg'
import detoure from './IMG/detoure.jpg'
import Demandedevis from '../../main/Demandedevis'


const Lettre = () =>{

    const [modalIsOpen, setIModalsOpen] = useState(false);
    const [image, setImage] = useState('')

    return (
        <div>
           <h1 className="h1texte">LES LETTRES LUMINEUSES DE NOS ENSEIGNES SONT FABRIQUEES SUR MESURE DANS NOS ATELIERS.<br/>
                ELLES SONT ÉQUIPEES DE LEDS PERFORMANTES ET DURABLES: LETTRES BOITIER, LETTRES RETRO-ECLAIREES,<br/>
                 LETTRES AVEC LEDS APPARENTES, LETTRES LUMIFORM. 
            </h1>
            <div>

                <div className="lineone">
                    <img src={lettre_suspendues_pharma} alt="lettre suspendues pharmacie" id="lettreSuspenduesPharmacie" className='lettrephotopetit'  onClick={() => {setIModalsOpen(true); setImage(lettre_suspendues_pharma)}}/>
                    <img src={lettre_pharmacie} alt="lettre pharmacie" id="lettrePharmacie" className='lettrephotopetit' onClick={() => {setIModalsOpen(true); setImage(lettre_pharmacie)}}/>
                </div>

                <div className="linetwo">

                    <div className="linetwocolone">
                        <div className="linetwocoloneonelineone">
                            <img src={lettre_retro_eclaire} alt="lettre retro eclaire" id="lettreretroeclaire" className='lettrephotopetit' onClick={() => {setIModalsOpen(true); setImage(lettre_retro_eclaire)}}/>
                        </div>
                        <div className="linetwocoloneonelinetwo">
                            <div className="linetwocoloneonelinetwocoloneone">
                                <img src={lettre_sante} alt="lettre sante" id="lettresante" className='lettrephotopetit' onClick={() => {setIModalsOpen(true); setImage(lettre_sante)}}/>
                                <img src={lettre_led_apparebte} alt="lettre led apparente" id="lettreledapparebte" className='lettrephotopetit' onClick={() => {setIModalsOpen(true); setImage(lettre_led_apparebte)}}/>
                            </div>
                            <div className="linetwocoloneonelinetwocolonetwo">
                                <img src={croix_ajour} alt="croix ajoure" id="croixajoure" className='lettrephotopetit' onClick={() => {setIModalsOpen(true); setImage(croix_ajour)}}/>

                            </div>
                        </div>
                    </div>

                    <div className="linetwocoltwo">
                        <img src={lettrtes_boitier} alt="lettre boitier" id="lettrtesBoitier" className='lettrephotopetit' onClick={() => {setIModalsOpen(true); setImage(lettrtes_boitier)}}/>
                    </div>

                    <div className="linetwocolthree">
                        <img src={detoure} alt="detour" id="detour" className='lettrephotopetit' onClick={() => {setIModalsOpen(true); setImage(detoure)}}/>
                    </div>

                </div>

            </div>
            <Modal className="Modal" overlayClassName="Overlay" isOpen={modalIsOpen} onRequestClose={() => setIModalsOpen(false)} >
            <img src={close} alt="ferme" id="imageclose"  onClick={() => {setIModalsOpen(false)}}/>
            <img src={image} alt="" className='modal_img'/>   
            </Modal>
            <div className='enseignedemandevis'>
                <Demandedevis code="Lettre"/>
            </div>
        </div>
    )
}

export default Lettre
