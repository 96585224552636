import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import './Menu.css'
import open_white from './IMG/menu_open-white-48dp.svg'
import close_black from './IMG/menu-black-48dp.svg'

const Menu = () => {

    const [open, setOpen] = useState(false)

    const menuOpen = () => {
        setOpen(!open)
    }
    return (
        <nav>
            <ul className='menuUL' style={{transform: open ? "translateX(0px)" : "" }}>
                <li className='menuLi'>
                    <NavLink to="/Croix" onClick={() => setOpen(false)}>Croix de Pharmacie</NavLink>
                </li >
                <li className='menuLi'>
                    <NavLink to="/Enseignes" onClick={() => setOpen(false)}>Enseignes</NavLink>
                </li>
                <li className='menuLi'>
                    <NavLink to="/Journal" onClick={() => setOpen(false)}>Journaux Lumineux</NavLink>
                </li>
                <li className='menuLi'>
                    <NavLink to="/Lettre" onClick={() => setOpen(false)}>Lettres Lumineuses</NavLink>
                </li>
                <li className='menuLi'>
                    <NavLink to="/Contact" onClick={() => setOpen(false)}>Contact</NavLink>
                </li>
                
                
                
            </ul>
            {open ? <img src={open_white} alt="fermer" id="close" onClick={menuOpen}/> :
            <img src={close_black} alt="ouvrir" id="menu" onClick={menuOpen}/>}
            
            
        </nav>
    )
}

export default Menu