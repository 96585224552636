import React , {useState} from 'react'
import Modal from 'react-modal'
import './enseignes.css'
import close from '../lettre/IMG/close.svg'
import enseigne1 from './IMG/enseigne1.jpg'
import enseigne2 from './IMG/enseigne2.jpg'
import enseigne3 from './IMG/enseigne3.jpg'
import enseigne4 from './IMG/enseigne4.jpg'
import enseigne5 from './IMG/enseigne5.jpg'
import enseigne6 from './IMG/enseigne6.jpg'
import enseigne7 from './IMG/enseigne7.jpg'
import enseigne8 from './IMG/enseigne8.jpg'
import enseigne9 from './IMG/enseigne9.jpg'
import enseigne10 from './IMG/enseigne10.jpg'
import enseigne11 from './IMG/enseigne11.jpg'
import enseigne12 from './IMG/enseigne12.jpg'
import Demandedevis from '../../main/Demandedevis'





function Enseignes() {

    const [modalIsOpen, setIModalsOpen] = useState(false);
    const [image, setImage] = useState('')

    return (
        <div>
            <h1 className='h1texte' >FABRIQUEES DANS NOS ATELIERS DE LA REGION PARISIENNE,
                 NOS ENSEIGNES SONT FABRIQUEES SUR MESURE. </h1>
            <div className='enseignesphototable'>
                <div className='enseignephotoligne'>
                    <img src={enseigne1} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne1)}}/>
                    <img src={enseigne2} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne2)}}/>
                    <img src={enseigne3} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne3)}}/>
                    <img src={enseigne4} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne4)}}/>
                </div>
                <div className='enseignephotoligne'>
                    <img src={enseigne5} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne5)}}/>
                    <img src={enseigne6} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne6)}}/>
                    <img src={enseigne7} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne7)}}/>
                    <img src={enseigne8} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne8)}}/>
                </div>
                <div className='enseignephotoligne'>
                    <img src={enseigne9} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne9)}}/>
                    <img src={enseigne10} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne10)}}/>
                    <img src={enseigne11} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne11)}}/>
                    <img src={enseigne12} alt="enseigne pharmacie" className='enseignephotopetite'  onClick={() => {setIModalsOpen(true); setImage(enseigne12)}}/>
                </div>
            </div> 
            <Modal className="Modal" overlayClassName="Overlay" isOpen={modalIsOpen} onRequestClose={() => setIModalsOpen(false)} >
            <img src={close} alt="ferme" id="imageclose"  onClick={() => {setIModalsOpen(false)}}/>
            <img src={image} alt="" className='modal_img'/>   
            </Modal> 
            <div className='enseignedemandevis'>
                <Demandedevis code="Enseignes"/>
            </div>  
        </div>
    )
}

export default Enseignes
