import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './journal.css'
import journal1 from './IMG/journal1.jpg'
import journal2 from './IMG/journal2.jpg'
import journal3 from './IMG/journal3.jpg'

function Slider() {
  return <Carousel 
        width="50%"
        autoPlay
        interval={5000}
        infiniteLoop
        thumbWidth={120}
        showStatus={false}
        className="slidergeneral">
            
      <div>
          <img src={journal1} alt="" />
          <p className='legend'>Simple Face</p>
      </div>
      <div>
          <img src={journal2} alt="" />
          <p className='legend'>Led vert</p>
      </div>
      <div>
          <img src={journal3} alt=""/>
          <p className='legend'>Double faces</p>
      </div>
  </Carousel>;
  
}

export default Slider;
