import React, {useState} from 'react'

const TypeColor = props =>  {

    const [color, setColor] = useState("#757575")
    const [border, setBorder] = useState("1px solid #757575")
    const [fontweights, setFontweights] = useState("unset")
    const [fontweightd, setFontweightd] = useState("unset")
    

    const focusin = () => {
        setColor("#000")
        setBorder("1px solid #000000")
        
    }

    const focusout = () => {
        setColor("#757575")
        setBorder("1px solid #757575")
        
    }

    const changes = (e) => {

        if(e.target.checked) {
            setFontweights("bold")
            setFontweightd("unset")
        }else {
            setFontweights("unset")
        }
    }

    const changed = (e) => {
        if(e.target.checked) {
            setFontweightd("bold")
            setFontweights("unset")
        }else {
            setFontweightd("unset")
        }
    }

    return (
        <div className="croixlineone">
        <fieldset className="croixField" style={{border:border}}>
            <legend style={{color:color}} className="croixlegendd">Couleur :</legend>
            
            <div className="Bloc">
                <div className="Line">
                    <input type="radio" id="fullcolor" name="type_color" value="fullcolor" onClick={(e) => props.setType_color(e.target.value)} onChange={changes} onFocus={focusin} onBlur={focusout} />
                    <label htmlFor="fullcolor" className="croixRadio" style={{fontWeight:fontweights}} >Full Color</label> {}        
                </div>
                <div className="Line">
                    <input type="radio" id="monocolor" name="type_color" value="monochrome" onClick={(e) => props.setType_color(e.target.value)} onChange={changed} onFocus={focusin} onBlur={focusout}/>
                    <label htmlFor="monocolor" className="croixRadio" style={{fontWeight:fontweightd}}>Mono Chrome</label>

                </div>
            </div>

            

        </fieldset>
    </div>
    )
}

export default TypeColor
