import React from 'react'
import { Link } from 'react-router-dom'
import SliderAccueil from './SliderAccueil'
import croix from './IMG/accueil/croix.jpg'
import enseigne from './IMG/accueil/enseigne.jpg'
import journal from './IMG/accueil/journal.jpg'
import lettre from './IMG/accueil/lettre.jpg'


function Accueil() {
    return (
        <div >
            <div>
                <h1 className='h1texte'>CONCEPTEUR ET FABRICANT D’IDENTITÉS
                    VISUELLES NEW WAYS VOUS ACCOMPAGNE
                    DANS TOUS VOS PROJETS D’ENSEIGNES,
                    DE SIGNALÉTIQUE ET D’HABILLAGES DE FAÇADES
                </h1>
            </div>
            <div className='sliderAccueil'>    
                <SliderAccueil/>
            </div>
            <div className='accueillineproduit'>
                <div className='accueillineproduitcolone'>
                    <Link to ="/Croix"><img src={croix} className="accueillineproduitobject" alt="croix de pharmacie"></img></Link>
                    <Link to ="/Croix" className='accueilllientext'>Croix de pharmacie</Link>
                </div>
                <div className='accueillineproduitcolone'>
                    <Link to ="/Enseignes"><img src={enseigne} className="accueillineproduitobject" alt="enseignes"></img></Link>
                    <Link to ="/Enseignes" className='accueilllientext'>Enseignes</Link>
                </div>
                <div className='accueillineproduitcolone'>
                    <Link to ="/Journal"><img src={journal} className="accueillineproduitobject" alt="journal lumineux"></img></Link>
                    <Link to ="/Journal" className='accueilllientext'>Journaux Lumineux</Link>
                </div>
                <div className='accueillineproduitcolone'>
                    <Link to="/Lettre"><img src={lettre} className="accueillineproduitobject" alt="Lettre"></img></Link>
                    <Link to ="/Lettre" className='accueilllientext'>Lettres</Link>
                </div>
            </div>
        </div>
    )
}

export default Accueil
