import React, {useState} from 'react'

const Faces = props => {

    const [color, setColor] = useState("#757575")
    const [border, setBorder] = useState("1px solid #757575")
    const [fontweights, setFontweights] = useState("unset")
    const [fontweightd, setFontweightd] = useState("unset")
    

    const focusin = () => {
        setColor("#000")
        setBorder("1px solid #000000")
        
    }

    const focusout = () => {
        setColor("#757575")
        setBorder("1px solid #757575")
        
    }

    const changes = (e) => {

        if(e.target.checked) {
            setFontweights("bold")
            setFontweightd("unset")
        }else {
            setFontweights("unset")
        }
    }

    const changed = (e) => {
        if(e.target.checked) {
            setFontweightd("bold")
            setFontweights("unset")
        }else {
            setFontweightd("unset")
        }
    }


    

    return (
        <div className="croixlineone">
        <fieldset className="croixField" style={{border:border}}>
            <legend style={{color:color}} className="croixlegendd">N° Face :</legend>
            
            <div className="Bloc">
                
                <div className="Line">
                    <input type="radio" id="Doubleface" name="type_faces" value="doublefaces"  onClick={(e) => props.setType_face(e.target.value)} onChange={changes} onFocus={focusin} onBlur={focusout} />
                    <label htmlFor="Doubleface" className="croixRadio" style={{fontWeight:fontweights}} >Double Face</label> {}        
                </div>
                <div className="Line">
                    <input  type="radio" id="Simpleface" name="type_faces" value="simpleface" onClick={(e) => props.setType_face(e.target.value)} onChange={changed} onFocus={focusin} onBlur={focusout}/>
                    <label htmlFor="Simpleface" className="croixRadio" style={{fontWeight:fontweightd}}>Simple Face</label>

                </div>
            </div>

            

        </fieldset>
    </div>
    )
}

export default Faces
