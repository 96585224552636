import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ac1 from './IMG/accueil/ac1.jpg'
import ac2 from './IMG/accueil/ac2.jpg'
import ac3 from './IMG/accueil/ac3.jpg'
import ac4 from './IMG/accueil/ac4.jpg'
import ac5 from './IMG/accueil/ac5.jpg'


function SliderAccueil() {
  return <Carousel
        width="70%"
        autoPlay
        interval={3000}
        infiniteLoop
        thumbWidth={120}
        showStatus={false}
        className="sliderAccueil">
    <div>
      <img src={ac1} alt=""/>
      <p className='legend'>Lettre lumineuse chant Alu face diffusante</p>
    </div>
    <div>
      <img src={ac2} alt=""/>
      <p className='legend'>Lettre en PMMA Laqué</p>
    </div>
    <div>
      <img src={ac3} alt=""/>
      <p className='legend'>Lettre lumineuse face et chant plexi diffusant</p>
    </div>
    <div>
      <img src={ac4} alt=""/>
      <p className='legend'>Habillage mât</p>
    </div>
    <div>
      <img src={ac5} alt=""/>
      <p className='legend'>Tôle ajourée avec lettre lumineuse PMMA face ete chant diffusant</p>
    </div>
  </Carousel>;
}

export default SliderAccueil;
