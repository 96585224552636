import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import croix1 from './IMG/croix1.jpg'
import croix2 from './IMG/croix2.jpg'
import croix3 from './IMG/croix3.jpg'
import croix4 from './IMG/croix4.jpg'
import croix5 from './IMG/croix5.jpg'

function Slidercroix() {
  return <Carousel 
  width="50%"
  autoPlay
  interval={5000}
  infiniteLoop
  thumbWidth={120}
  showStatus={false}
  className="slidergeneral">
<div>
    <img src={croix1} alt="croix pharmacie" />
    <p className='legend'>Croix 1080 Double face Full color</p>
</div>
<div>
    <img src={croix2} alt="croix pharmacie" />
    <p className='legend'>Avec Potence déporté</p>
</div>
<div>
    <img src={croix3} alt="croix pharmacie"/>
    <p className='legend'>Croix 1440 En crucifix</p>
</div>
<div>
    <img src={croix4} alt="croix pharmacie"/>
    <p className='legend'>Mono color</p>
</div>
<div>
    <img src={croix5} alt="croix pharmacie"/>
    <p className='legend'>Full color</p>
</div>
</Carousel>
}

export default Slidercroix;
