import React, {useState} from 'react'
import Modal from 'react-modal'
import close from './IMG/close.svg'


const Imgcroix = props => {

    const [modalIsOpen, setIModalsOpen] = useState(false);
    
    
    return (
        <div>
            { (props.img_small !=="") && <img src={props.img_small} alt="Croix easy" className='croix_img'  onClick={() => setIModalsOpen(true)}/>}
           
            <Modal className="Modal" overlayClassName="Overlay" isOpen={modalIsOpen} onRequestClose={() => setIModalsOpen(false)} >
               <img src={close} alt="ferme" id="imageclose" onClick={() => {setIModalsOpen(false)}}/>
               <img src={props.img_big} alt="mini pll big" className='modal_img'/>   
            </Modal>
            
        </div>
    )
}

export default Imgcroix
